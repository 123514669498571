import * as actionType from './constants'
import { handleServerErrorNew, handleServerSuccessNew } from './common'
import * as Services from '../api/index'
import Analytics from '../../utils/Analytics'

export const clearCart = () => (dispatch) => dispatch({ type: actionType.CLEAR_CART })

export const getUserCart = () => {
  return (dispatch) => {
    dispatch({
      type: actionType.GET_CART_LOADING,
      payload: { loading: true },
    })
    Services.getCart()
      .then((response) => {
        handleServerSuccessNew(
          actionType.GET_CART_SUCCESS,
          actionType.GET_CART_LOADING,
          response,
          dispatch,
        )
      })
      .catch((err) => {
        handleServerErrorNew(actionType.GET_CART_ERROR, actionType.GET_CART_LOADING, err, dispatch)
      })
  }
}

export const addItemToCart = (req) => {
  return (dispatch) => {
    dispatch({
      type: actionType.GET_CART_LOADING,
      payload: { loading: true },
    })
    Services.addToCart({ listing: req.data.listing.id })
      .then((response) => {
        if (req.onSuccess) {
          req.onSuccess(response)
        }
        dispatch(getUserCart())
        handleServerSuccessNew(
          actionType.ADD_NEW_CART_ITEM_SUCCESS,
          actionType.GET_CART_LOADING,
          response,
          dispatch,
        )
        Analytics.track('Add to Cart', {
          id: req.data.listing.id,
          buyer_price: req.data.listing.buyer_price,
          description: req.data.listing.description,
          slug: req.data.listing.slug,
        })
      })
      .catch((err) => {
        if (req.onFail) {
          req.onFail(err)
        }
        handleServerErrorNew(
          actionType.ADD_NEW_CART_ITEM_ERROR,
          actionType.GET_CART_LOADING,
          err,
          dispatch,
        )
      })
  }
}

export const deleteItemFromCart = (id) => {
  return (dispatch) => {
    dispatch({
      type: actionType.GET_CART_LOADING,
      payload: { loading: true },
    })
    Services.deleteFromCart(id)
      .then((response) => {
        dispatch(getUserCart())
        handleServerSuccessNew(
          actionType.DELETE_CART_ITEM_SUCCESS,
          actionType.GET_CART_LOADING,
          response,
          dispatch,
        )
      })
      .catch((err) => {
        handleServerErrorNew(
          actionType.DELETE_CART_ITEM_ERROR,
          actionType.GET_CART_LOADING,
          err,
          dispatch,
        )
      })
  }
}

export const addFavorite = (item) => {
  return (dispatch) => {
    dispatch({ type: actionType.ADD_NEW_FAVORITE_ITEM, payload: { item } })
  }
}

export const deleteFavorite = (id) => {
  return (dispatch) => {
    dispatch({ type: actionType.DELETE_FAVORITE_ITEM, payload: { id } })
  }
}

export const cartCheckout = (req) => {
  return (dispatch) => {
    Services.cartCheckout(req.cartData)
      .then((response) => {
        if (req.onSuccess) {
          req.onSuccess(response)
        }
        handleServerSuccessNew(
          actionType.CHECKOUT_SUCCESS,
          actionType.CHECKOUT_LODING,
          response,
          dispatch,
        )
        Analytics.track('Checkout', { total: response.total, items: response.count })
      })
      .catch((err) => {
        if (req.onFail) {
          req.onFail(err)
        }
        handleServerErrorNew(actionType.CHECKOUT_ERROR, actionType.CHECKOUT_LODING, err, dispatch)
      })
  }
}

export function updateLocalCart(payload) {
  return {
    type: actionType.UPDATE_LOCAL_CART,
    payload,
  }
}

export function clearLocalCart() {
  return {
    type: actionType.REMOVE_LOCAL_CART,
  }
}
